import styled from 'styled-components'

export const Flexed = styled.section`
    display: flex;
    flex-wrap: wrap;

    //@ts-ignore
    padding: ${ props => props.sectionPadding };

    > * {
        width: 100%;
        flex-basis: 100%;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 50%;
        }
    }

    > :first-child {
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            padding-right: 2vw;
        }
        > * {
            text-align: left;
        }
    }

    > :last-child {
        align-self: center;
    }
`

Flexed.defaultProps = {

	//@ts-ignore
	sectionPadding: '64px 0',

}

export const Article = styled.article`
    h1, h2, h3, h4, h5, h6 {
        margin-left: 0 !important;
    }
`