import * as React from 'react';
import styled from 'styled-components'
import { StyledListWithMarker } from './Lists'
import cn from 'classnames';
interface IListItems {
    summary?: string;
    title?:string
}
interface ITabbedSectionProps {
    listItems?: IListItems[]
}

const StyledTabbedSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex-direction: row;
    }
    .col-1 {
        margin-bottom: 10px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex: 0 0 33.33%;
            margin-bottom: 0px;
        }
        li {
            cursor: pointer;
            &.is-active {
                background: var(--nearlyNavy);
                color: var(--mobyDickWhite);
            }
        }
    }
    .col-2 {
        width: 100%;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex: 0 0 66.66%;
        }
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            height: 200px;
        }
    }
`;



const StyledTabbedContentWrapper = styled.div`
    background: var(--nearlyNavy);
    height: 100%;
    margin-left: 0px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        margin-left: 10px;
    }
    .inner-wrapper {
        position: relative;
        overflow: hidden;

        > div {
            position: absolute;
            padding: 32px;
            z-index: 0;
            opacity:0;
            transition: opacity .2s ease-in;

            &.is-active {
                z-index: 1;
                opacity:1;
                position: relative;
                min-height: 220px;
            }
        }

        h3 {
            color: var(--meaningfulMarigold);
            text-transform: uppercase;
            margin-bottom: 20px;
            font-size: 24px;
        }

        p {
            color: var(--mobyDickWhite) !important;
            font-weight: 100;
            font-size: 16px;
        }
    }

`;


const TabbedSection: React.FC<ITabbedSectionProps> = (props) => {
    const [isActive, setIsActive] = React.useState('tab-0');
    const handleClick = (event) => {
        event.preventDefault();
        const currentTab = event.target.dataset.key;
        setIsActive(currentTab);
    }
    return (
        <StyledTabbedSection>
        <div className="col-1">
            <ul>
                {props?.listItems?.map((item,index) =>
                    <StyledListWithMarker
                        data-key={`tab-${index}`}
                        key={`tab-title-${index}`}
                        onClick={handleClick}
                        className={cn('tab-title', { 'is-active': isActive === `tab-${index}`})}
                    >
                        {item.title}
                    </StyledListWithMarker>
                )}
            </ul>
        </div>
        <div className="col-2">
            <StyledTabbedContentWrapper>
                <div className="inner-wrapper">
                    {props?.listItems?.map((item, index) => (
                        <div
                            key={`tab-content-${index}`}
                            data-key={`tab-${index}`}
                            className={cn('tab-content', { 'is-active': isActive === `tab-${index}`})}
                        >
                            <h3>{item.title}</h3>
                            <div>
                                <p>{item.summary}</p>
                            </div>
                        </div>
                    )
                    )}
                </div>

            </StyledTabbedContentWrapper>
        </div>
    </StyledTabbedSection>
    )
}

export default TabbedSection