import * as React from 'react';
import styled from 'styled-components';
import GoCircle from '../../../images/icons/go-circle.svg';

interface IMemberDetails {
    image?: string;
    tagline?: string;
    name?: string;
}

interface IFocusCard {
    members: Array<IMemberDetails>;
}

const Focus = styled.article`
    width: 100%;
    align-self: center;

    @media (max-width: ${props => props.theme.screensizes.medium}) {
        margin: 30px auto;
    }
`

const Wrapper = styled.div`
    border: 1px solid ${props => props.theme.colors.casperGrey};
    padding: 24px;
    margin: 0 auto;

   
`;

const FocusCardHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    svg {
        margin-right: 8px;
    }
    
    h3 {
        text-transform: uppercase;
        font-size: 24px;
        margin-left: 10px;
    }
`

const FocusCardInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-wrapper {
        border-radius: 50%;
        flex:  0 0 21%;
        margin-right: 20px;

        img {
            border-radius: 50%;
        }
    }
    .summary-wrapper {
        margin-bottom: 10px;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            max-width: 80%;
        }
        p {
            margin-bottom: 10px;

            &.position {
                font-weight: 600;
            }
        }
    }
`;

const FocusCard: React.FC <IFocusCard> = ( props ) => {
    return (
        <Focus>
            <Wrapper>
                <FocusCardHeader>
                    <GoCircle />
                    <h3>Team Focus</h3>
                </FocusCardHeader>
                { props.members.map((member, index) => (
                    <FocusCardInner key={ index }>
                        { member?.image && (
                            <div className="img-wrapper">
                                <img src={ member.image } alt="Team Focus"/>
                            </div>
                        )}
                        <div className="summary-wrapper">
                            {member?.tagline && (
                                <p>{ member.tagline }</p>
                            )}
                            {member?.name && (
                                <p className="position">{ member.name }</p>
                            )}
                        </div>
                    </FocusCardInner>
                )) }
            </Wrapper>
        </Focus>
    );
};



export default FocusCard;