import * as React from 'react'
import { gql, useQuery } from '@apollo/client';

// Components
import { BrandedTitle } from '../Components/Common/BrandedTitle'
import PostsSlider from '../Components/Common/CaseStudiesSlider'
import { ContentContainer } from '../Components/Common/ContentContainer'
import { HeroBanner } from '../Components/Common/HeroBanner'
import ServicesGrid from '../Components/Common/ServicesGrid'
import SupplierSlider from '../Components/Common/SupplierSlider'
import GenericBanner from '../Components/Common/GenericBanner'
import TabbedSection from '../Components/Common/TabbedSection'
import styled from 'styled-components'


import { Loading } from '../Components/Common/Loading'
import { Error } from '../Components/Common/404'
import { Paragraph } from '../Components/Common/Styles/Typography'
import { RequestForm } from '../Components/Common/RequestForm';

// Common Styled Components
import { Flexed, Article } from '../Components/Common/Styles/globalStyles';
import WeAreCertified from '../Components/Common/WeAreCertified';
import { Collapsible } from '../Components/Common/Collapsible';
import { Buttons } from '../Components/Common/Styles/Interactions/Buttons';
import FocusCard from '../Components/Common/Team/FocusCard';

const SEARCH_QUERY = gql`
    query Page($id: ID!) {
        page(idType: URI, id: $id) {
			uri
			title
			content
            teamFocus {
                members {
                    name
                    tagline
                    image {
                        sourceUrl
                    }
                }
            }
			pageSupportCustomFields {
                faqs {
                    answer
                    question
                }
                support {
                    backgroundImage {
                        sourceUrl
                    }
                    entryTitle
                    excerpt
                    points {
                        title
                        summary
                    }
                }
			}
        }
    }
`;

const OurApproach = styled.section<{ backgroundImageUrl: string }>`
    position: relative;
    background: url('${props => props.backgroundImageUrl}'), rgba(29, 35, 64, 0.9);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-blend-mode: multiply;
    padding: 16px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 64px 128px;
    }

    h2 {
        margin-left: 0 !important;
        text-align: left;
        font-size: 40px;
        font-weight: 400;
    }

    p {
        margin: 30px 0 0;
        font-weight: 300;
        font-style: normal;
        color: ${ props => props.theme.colors.greatlyGrey };

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            padding-left: 16px;
        }
    }

    p:first-of-type {
        color: ${ props => props.theme.colors.nearlyNavy };
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        padding-left: 0;
        margin-bottom: 30px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            padding-right: 16px;
        }
    }

    .has-normal-font-size {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;

    }

    article {
        display: flex;
        flex-wrap: wrap;

        &:first-of-type {
            padding-bottom: 32px;
            border-bottom: 1px solid ${props => props.theme.colors.casperGrey};


            > :first-child,
            > :last-child {
                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    flex-basis: 50%;
                }
            }
        }

        &:last-of-type {
            padding-top: 32px;

            h3 {
                text-transform: uppercase;
                margin-bottom: 16px;
                font-size: 24px;
                font-family: var(--dinNarrow-font);
                font-weight: 600;
            }

            > :first-child {
                flex-basis: 100%;
                padding-bottom: 32px;

                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    flex-basis: 60%;
                    padding-right: 32px;
                    padding-bottom: 0;
                }
            }

            > :last-child {
                flex-basis: 100%;

                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    flex-basis: 40%;
                }
            }
        }
    }

    .has-extra-small-font-size {
        color: ${props => props.theme.colors.greatlyGrey};
    }

    .content-container {
        background: var(--mobyDickWhite);
        margin: 0 auto;
        padding: 48px 32px !important;
        max-width: 1180px;

        @media (min-width: ${props => props.theme.screensizes.large}) {
            padding: 64px 94px !important;
        }
    }

    [class^="Secondary__Button"] {
        max-width: 222px;
        margin: 50px auto 0px auto;
    }
`;

const FAQs = styled.section`
    display: flex;
    flex-wrap: wrap-reverse;
    margin: 64px 0;

    > :first-child {
        flex-basis: 100%;
        margin-top: 48px;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 40%;
            margin-top: 0;
            margin-left: 0;
        }

        ul {
            display: block;
            text-align: center;

            li {
                display: inline-block;
                width: 50%;
                padding: .78571rem;
                border: initial;
                margin: 0;
                max-width: 135px;

                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    max-width: 160px;
                }
            }
        }
    }

    > :last-child {
        flex-basis: 100%;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 60%;
        }

        h2 {
            text-align: left;
            margin-left: 0 !important;
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 32px;
            font-weight: 300;
        }
    }

    .collapsible {
        .title-container-alt { padding: 16px; }

        .title-inner {
            width: 100%;
            background-color: ${ props => props.theme.colors.casperGrey };

            .title {
                margin-bottom: 0;
                font-size: 16px;
                text-transform: none;
                font-weight: 600;
            }
        }
    }
`


const SupportPage: React.FC = (props) => {
    const { loading, error, data  }  = useQuery(SEARCH_QUERY, {
        fetchPolicy: "no-cache",
        variables: { id: '/support/' }
    });
    

    if (loading) return <Loading />;
	if (error) return <Error />;

    return (
        <>
            <HeroBanner pageSlug="support" />
            <>
                <ContentContainer>
                    <Flexed>
                        <Article>
                            <BrandedTitle>{data?.page?.pageSupportCustomFields?.support?.entryTitle}</BrandedTitle>

                            { data?.page?.content && (
                                <Paragraph.Block text={data?.page?.content}></Paragraph.Block>
                            )}

                            { data?.page?.teamFocus?.members?.length && (
                                <FocusCard
                                    members={data.page.teamFocus.members.map(member => (
                                        {
                                            image: member?.image?.sourceUrl || '',
                                            tagline: member?.tagline || '',
                                            name: member?.name || '',
                                        }
                                    ))}
                                />
                            ) }
                        </Article>
                        <RequestForm
                            formId={ 1 }
                            formTitle='Ready to Talk'
                            formTagline='Complete the form below and a member of our team will be in touch shortly to discuss your requirments.'
                            presetValues={{input_12: data?.page?.title}}
                        />
                    </Flexed>
                </ContentContainer>
            </>

            <SupplierSlider />

            <OurApproach backgroundImageUrl={data?.page?.pageSupportCustomFields?.support?.backgroundImage.sourceUrl || ''}>
                <ContentContainer>
                    <BrandedTitle>Our Support</BrandedTitle>
                    { data?.page?.pageSupportCustomFields?.support?.excerpt && (
                        <p dangerouslySetInnerHTML={{__html: data.page.pageSupportCustomFields.support.excerpt}}></p>
                    )}
                    { data?.page?.pageSupportCustomFields?.support?.points && (
                        <TabbedSection 
                            listItems={data?.page?.pageSupportCustomFields?.support.points.map(point => (
                                {
                                    title:point.title,
                                    summary:point.summary
                                }
                            ))} 
                        />
                    )}
                    <Buttons.Secondary text="Contact Us" href="/contact-us" target="_self"/>
                </ContentContainer>
            </OurApproach>

            <ServicesGrid title="Support Services"  backgroundColor={ 'white' }/>

            <ContentContainer>
                <FAQs>
                    <WeAreCertified/>
                    <article>
                        <BrandedTitle>FAQs</BrandedTitle>
                        <p className="content-intro">
                            If you can’t find the answer to your question below, a member of our team will be happy to help. Call us on 01704 330315.
                        </p>
                        { data?.page?.faqs?.length && (
                            data.page.faqs.map((faq, index) => (
                                <Collapsible key={ index } title={ faq.question } alt={ true } altArrow={ true }>
                                    <div className="content-inner">
                                        <div className="inner-content" dangerouslySetInnerHTML={{__html: faq.answer}}></div>
                                    </div>
                                </Collapsible>
                            ))
                        )}
                    </article>
                </FAQs>
            </ContentContainer>

            <PostsSlider />

            <GenericBanner />
        </>
    )
}


export default SupportPage;