import * as React from 'react';
import styled from'styled-components'
import { useStaticQuery, graphql } from 'gatsby';
import { ContentContainer } from './ContentContainer';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';
import { Icons } from '..';

const StyledCaseStudiesSlider = styled.div`
    background: var(--nearlyNavy);
    .content-container {
        margin: 0 auto;
        padding: 2rem !important;
        max-width: 95%;
        @media (min-width: ${props => props.theme.screensizes.large}) {
            padding: 7rem 0 !important;
            max-width: 1028px;
            margin: 0 auto;
        } 
    }
    .slick-post-wrapper {

        .col-wrapper {
            @media (max-width: ${props => props.theme.screensizes.medium}) {
                flex-direction: column-reverse !important;
                margin-bottom: 40px;
            }
        }
        h3.post-title {
            color: var(--meaningfulMarigold);
            text-transform:uppercase;
            margin-bottom:20px;
            margin-top:20px;
        }
        .summary {
            font-weight:300;

            .more-link {
                display: none;
            }
        }

        p {
            color: var(--mobyDickWhite);

            &.content-intro {
                font-size:20px;
                line-height:25px;

                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    font-size:24px;
                    line-height:31px;
                }
            }
        }

        .read-more {
            color: var(--mobyDickWhite);
            font-family: var(--din-font);
        }
    }

    .slick-arrow {
        transition: all  0.3s ease-in-out;
        &:hover {
            color: var(--meaningfulMarigold);
            opacity:0.7;
        }
    }
    .slick-next {
        right: -50px;
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            top: 98%;
            right: -5px;
        }
    }
    .slick-prev {
        transform: rotate(180deg);
        left: -50px;
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            top: 96%;
            left: -5px;
        }
    }
    .slick-dots {
        position: absolute;
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            top: 98%;
        }
        @media (min-width: ${props => props.theme.screensizes.large}) {
            bottom: -60px;
        }
        button {
            &:before {
                width: 16px;
                height: 16px;
                border: 1px solid var(--meaningfulMarigold);
                border-radius:50%;
                opacity:1;
                content:'';
            }
            &:hover {
                background: var(--mobydickWhite);
            }
        }
        
        .slick-active {
            button {
                &:before {
                    background-color: var(--meaningfulMarigold);
                    opacity:1;
                }
                &:hover {
                    transition: all  0.3s ease-in-out;
                    &:hover {
                        color: var(--meaningfulMarigold);
                        opacity:0.7;
                    }
                }
            }
        }
    }
`;

const PostsSlider: React.FC = (props) => {


    const { caseStudies } = useStaticQuery(graphql`
        query {
            caseStudies: wordpress {
                caseStudies(where: {orderby: {field: DATE, order: ASC}}, first: 3) {
                    nodes {
                        id
                        title
                        excerpt
                        uri
                        featuredImage {
                            node {
                                sourceUrl(size: POST_THUMBNAIL)
                            }
                        }
                    }
                }
            }
        }
    `);
    
    const caseStudiesArr = caseStudies?.caseStudies?.nodes;
   
    return (

        <StyledCaseStudiesSlider>
            <ContentContainer>
            <Slider
                slidesToShow={1}
                slidesToScroll={1}
                autoplay
                infinite={true}
                speed={1000}
                autoplaySpeed={4000}
                fade={true}
                nextArrow={<Icons.Play width={'30px'} />}
                prevArrow={<Icons.Play width={'30px'} />}
                arrows={true}
                dots={true}
                >
                { caseStudiesArr.map((casestudy, index) => {
                    const imageSrc = casestudy.featuredImage?.node.sourceUrl;
                    const excerpt = ReactHtmlParser(casestudy?.excerpt);
                    return (
                        <div className="slick-post-wrapper" key={`case-study__${casestudy.id}`}>
                            <div className="col-wrapper">
                                <div className="col-40">
                                    <h3 className="post-title">Case Study</h3>
                                    <p className="content-intro">{casestudy.title}</p>
                                    <div className="summary">{excerpt}</div>
                                    <a href={casestudy.uri} className='with-icon read-more'>Read More <Icons.Play width={'20px'} /></a>
                                </div>
                                <div className="col-60">
                                    <div className="img-wrapper">
                                        <img src={imageSrc} />
                                    </div>
                                </div>
                            </div>
                        
                          
                        </div>
                    )
                   
                })
                }
            </Slider>
            </ContentContainer>
        </StyledCaseStudiesSlider>
        
    )
}

export default PostsSlider;