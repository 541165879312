import * as React from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
    min-height: 650px;
`

const Loader = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: zIndex('higher');

    .loader__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 185px;
        background: rgba(0, 0, 0, 0.7);
        padding: 1.5rem;
        text-align: center;
        border-radius: 0.25rem }

    .loader__spinner-text {
        display: none;
    }

    .loader__text {
        font-size: 16px;
        color: white;
        margin-top: 0.78rem;
        font-weight: 300;
        margin-bottom: 0; }

    @keyframes spinner-border {
        to {
        transform: rotate(360deg); } }

    .spinner-border {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        vertical-align: text-bottom;
        border: 0.20em solid white;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border .75s linear infinite;
        color: #f8f9fa }

    .spinner-border-sm {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em; }
`

export const Loading: React.FC<{}> = () => {
    return (
        <LoaderWrapper>
        <Loader>
            <div className="loader__container">
                <div
                    className="spinner-border text-light loader__spinner"
                    role="status"
                >
                    <span className="sr-only loader__spinner-text">
                        Loading...
                    </span>
                </div>
                <p className="mt-2 mb-0 loader__text">
                    Loading...
                </p>
            </div>
        </Loader>
        </LoaderWrapper>
    );
}