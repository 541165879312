import * as React from 'react'
import styled from 'styled-components'

// Contexts
import { GlobalContext } from '@/src/contexts/GlobalContext'

const StyledGenericBanner = styled.div`
    background: var(--mobyDickWhite);
    padding: 16px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 64px 128px;
    }
 
    .content-container {
        margin: 0 auto;
    }
`
const Wrapper = styled.div<{ backgroundImageUrl: string }>`
    display: flex;
    align-items:center;
    flex-wrap: wrap;
    position: relative;
    background: url('${props => props.backgroundImageUrl}'), ${props => props.theme.colors.nearlyNavy};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 24px 16px;
    margin: auto;
    max-width: 1180px;

    @media (min-width: ${props => props.theme.screensizes.large}) {
        padding: 32px 198px;
    }

    h2 {
        font-weight:bold;
        margin-bottom:10px;
        text-transform: uppercase;

        @media (max-width: ${props => props.theme.screensizes.medium}) {
            text-align:center;
            font-size:24px;
            line-height: 28px;
        }
    }

    p {
        margin-bottom:0px;
        font-size: 20px;
        line-height:25px;
        @media (max-width: ${props => props.theme.screensizes.medium}) {
            text-align: center;
            font-size: 16px;
            margin-bottom:20px;
        }
    }
    .img-wrapper {
        width: 201px;
        line-height: 0px;
        margin: auto;

        img {
            line-height: 0px;
            box-shadow: 2px 2px 4px #1D234033;
        }
    }

    a {
        margin: 8px 0;
        color:  ${props => props.theme.colors.nearlyNavy};
        font-weight: 300;
        text-align: center;
        text-decoration: underline;
        display: block;
    }
`;


const GenericBanner: React.FC = () => {
    const { themeSettings } = React.useContext(GlobalContext);
    if (!themeSettings?.banner) return null;

    return (
        <StyledGenericBanner>
            <Wrapper className="col-wrapper" backgroundImageUrl={themeSettings?.banner?.backgroundImage?.sourceUrl || ''}>
                <div className="col-60">
                    { themeSettings?.banner?.headline && (
                        <h2>{ themeSettings.banner.headline }</h2>
                    )}
                    { themeSettings?.banner?.subheading && (
                        <p>{ themeSettings.banner.subheading }</p>
                    )}
                </div>
                <div className="col-40">
                    <div className="img-wrapper">
                        { themeSettings?.banner?.cta?.image?.sourceUrl && (
                            <img src={ themeSettings.banner.cta.image.sourceUrl }/>
                        )}
                        <a
                            { ...themeSettings?.banner?.cta?.link && { href: themeSettings?.banner?.cta?.link }}
                            target="_blank"
                        >
                            { themeSettings?.banner?.cta?.title }
                        </a>
                    </div>
                </div>
            </Wrapper>
        </StyledGenericBanner>
    )
}

export default GenericBanner;