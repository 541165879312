import * as React from 'react';
import styled from 'styled-components';
import { ContentContainer } from './ContentContainer';
import { SearchInput } from './Search/SearchInput';
import { Link } from './Styles/Typography/Links/Link';

const ErrorComponent = styled.section`
    text-align: center;
    background-color: var(--almostWhite);

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 48px 5vh;
    }

    .content-container {
        background-color: var(--mobyDickWhite);
        height: 100%;
        padding-top: 5vh;
        padding-bottom: 5vh;
        article {
            display : flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    h2 {
        font-size: 32px !important;
        margin-bottom: 32px;
    }
    h3 {
        font-size: 25px;
    }
    p {
        font-size: 20px;
        margin-bottom: 24px;
        font-weight: 300;

        &:not(:first-of-type) {
            font-size: 16px;
        }
    }
    ul {
        margin: 4px 0 24px;

        li {
            margin-bottom: 10px;
            font-size: 16px;

            a {
                text-decoration: underline;
                color: var(--nearlyNavy);
                font-size: inherit;
            }
        }
    }
`

export const Error: React.FC<{}> = () => {
    return (
        <>
            <ErrorComponent>
                <ContentContainer>
                    <h2>404 Error - Page Not Found</h2>
                    <p>
                        We can't seem to find the page you're looking for:
                    </p>
                    <p>
                        Please try the following links:
                        <ul>
                            <li><Link text={ 'Home' } link={ '/' }/></li>
                            <li><Link text={ 'Contact Us' } link={ '/contact-us/' }/></li>
                        </ul>
                    </p>
                    <p>
                        Or try using search to find what you’re looking for:
                    </p>
                    <SearchInput inputType={ 'alternative' }/>
                </ContentContainer>
            </ErrorComponent>
        </>
    );
}